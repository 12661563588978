body {
  background-color: #202125;
  font-family: "Roboto condensed", sans-serif;
  color: #faffff;
  max-width: 1400px;
  margin: 0 auto;
  font-size: 25px;
  padding: 30px;
}
section {
  margin-top: 50px;
}

.social-links {
  padding-top: 17px;
}
a {
  color: #62ffa1;
  text-decoration: none;
}

.header {
  display: flex;
  flex-direction: row;
  margin: auto;
  padding-top: 25px;
}

.code > div {
  display: flex;
}

.code-content {
  max-width: 23.8%;
}

.code-content:not(:first-child) {
  padding-left: 1%;
}

.code-content > img {
  width: 100%;
}

.code-content .title {
  font-size: 20px;
}

.code-content .subtitle {
  color: #62ffa1;
}

.code-content .description {
  margin-top: 10px;
  color: #ff7a7a;
}

.header .titles {
  /* margin: auto 0; */
}

.header .subtile {
  margin-right: 50px;
}
.profile-picture {
  display: flex;
  justify-content: center;
  width: 30%;
}

.profile-picture > img {
  width: 256px;
  margin: auto;
  
}

section.photo img {
  width: 23.8%;

  margin: auto;
}

section.photo img:not(:last-child) {
  padding-right: 1%;
}

section.music iframe {
  width: 23.8%;
  height: 500px;
}

section.music iframe:not(:last-child) {
  padding-right: 1%;
}
footer {
  margin-top: 40px;
}

@media only screen and (min-width: 1077px) {
}

@media only screen and (max-width: 800px) {
  .hide-on-mobile {
    display: none !important;
  }

  .profile-picture > img {
    width: 120px;
    margin: auto;
  }
}
